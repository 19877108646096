import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/axios'

import dataV from '@jiaminghi/data-view'
Vue.use(dataV)

import * as echarts from 'echarts'
Vue.use(echarts);
Vue.prototype.$echarts = echarts;

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import { on } from 'element-ui/src/utils/dom'
Vue.use(ElementUI);

import Pagination from '@/components/Pagination'
Vue.component('Pagination', Pagination)

import VueCookies from 'vue-cookies';
Vue.use(VueCookies);
Vue.prototype.VueCookies = VueCookies;

// main.js
import closePagePlugin from './plugins/closePagePlugin'

Vue.use(closePagePlugin)

// import 'lib-flexible'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
