<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
   data() {
		return {
			
		}
    },
	mounted() {
		document.title = '蚌埠二中业务考核积分系统';
	}
}
</script>

<style lang="scss">
@import url("./assets/css/reset.css");
@import url("./assets/css/common.css");
@import url("./assets/css/style.scss");
</style>
